<template>
	<div class="flex-col page">
		<div class="flex-col section_1">
			<span class="text_1">{{ title }}</span>
		</div>
		<div class="flex-col section_3">
			<div class="flex-col">
				<span class="list-item" :key="i" v-for="(title, i) in list">
					{{ i + 1 }}、{{ title.upkeepName }} <br />
					<br />
					<span style="color: #4e4e4e">{{ title.upkeepRequire }}</span>
					<van-radio-group v-model="radio[i].status" direction="horizontal" disabled>
						<van-radio name="0">检查正常</van-radio>
						<van-radio name="1">润滑</van-radio>
						<van-radio name="2">调整</van-radio>
						<van-radio name="3">更换</van-radio>
						<van-radio name="4">异常现象待修复</van-radio>
						<van-radio name="5">无此项</van-radio>
					</van-radio-group>
				</span>
			</div>
		</div>
	</div>
</template>

<script>
import { itemList } from "@/api/Property/Maintenance/all";

export default {
	data() {
		return {
			list: [
				 
			],
			title: "维保任务",
			radio: [
			 
			],
			Item: [],
		};
	},
	mounted() {
		// 初始渲染需要维保的选项列
		itemList({ id: this.$route.params.id }).then((res) => {
			if (res.data.Result === 200) {
				
				let resData = res.data.Data;
				this.radio = [];
				for (var i = 0; i < resData.data.length; i++) {
					console.log(resData.data[i].status);
					this.radio.push({ id: resData.data[i].id, status: resData.data[i].status.toString() });
				}
				console.log(this.radio);
				this.list = resData.data;
			}
		});
	},
};
</script>

<style scoped>
@import "../../../assets/common.css";
.list-item {
	margin-left: 0.88rem;
	color: rgb(0, 0, 0);
	font-size: 1rem;
	line-height: 1.06rem;
	white-space: nowrap;
}
.page {
	background-color: rgb(255, 255, 255);
	height: 100%;
	width: 100%;
	overflow-y: auto;
}
.section_1 {
	padding: 0 0 5.13rem;
	background: url("../../../assets/imgbg1_03.png") center no-repeat;
	background-size: cover;
}
.section_3 {
	margin: -2.56rem 0 -4.88rem;
	padding: 1.44rem 0.44rem 6.44rem;
	background: #fff;
	border-radius: 1rem 1rem 0 0;
	position: relative;
}
.section_2 {
	padding: 0.88rem 0.81rem;
	color: rgb(0, 0, 0);
	font-size: 1rem;
	line-height: 1rem;
	white-space: nowrap;
	background-color: rgb(237, 237, 237);
}
.text_1 {
	margin-left: 1.5rem;
	margin-top: 1.88rem;
	color: rgb(255, 255, 255);
	font-size: 1.5rem;
	line-height: 1.44rem;
	white-space: nowrap;
}
.button {
	margin: 26.63rem 0.81rem 0;
	padding: 1.06rem 0;
	color: rgb(255, 255, 255);
	font-size: 1rem;
	line-height: 1rem;
	white-space: nowrap;
	background-image: url("../../../assets/buttonbg.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
}
.image_1 {
	margin-right: 0.25rem;
	align-self: center;
	width: 1.13rem;
	height: 0.25rem;
}
.image {
	margin-bottom: 0.13rem;
	width: 0.81rem;
	height: 0.81rem;
}
.text {
	margin-left: 0.69rem;
}
.van-radio-group {
	padding: 1rem 0;
}
</style>
